const __getMdns = {
  method: "GET",
  url: "mdn/",
};

const __exportMdns = {
  method: "GET",
  url: "mdn/export",
};
const __getMdnById = (id) => ({
  method: "GET",
  url: `mdn/${id}`,
});

const __updateMdn = (id) => ({
  method: "PUT",
  url: `mdn/${id}`,
});

const __addMdns = {
  method: "POST",
  url: `mdn/add`,
};

const __assignMdns = {
  method: "POST",
  url: `mdn/assign`,
};

const __resetPort = (id) => ({
  method: "POST",
  url: `mdn/${id}/reset`,
});

const __sendSMS = (id) => ({
  method: "POST",
  url: `mdn/${id}/send_sms`,
});

const __clearMdns = {
  method: "POST",
  url: `mdn/clear_mdns`,
};

const __resetNetwork = (id) => ({
  method: "POST",
  url: `mdn/${id}/reset_network`,
});

const __getMDNStatus = {
  method: "GET",
  url: `mdn/status`,
};

const __getMDNExportList = {
  method: "GET",
  url: "mdn/export_mdn",
};

const __getMDNExport = {
  method: "GET",
};

const __triggerMDNExport = {
  method: "POST",
  url: "mdn/export_mdn",
};

const __getMDNExportData = (id) => ({
  method: "GET",
  url: `mdn/export_mdn/${id}/data`,
});

const __batchResetPort = {
  method: "POST",
  url: `mdn/batch_reset_port`,
}

export {
  __getMdns,
  __addMdns,
  __sendSMS,
  __resetPort,
  __updateMdn,
  __getMdnById,
  __assignMdns,
  __clearMdns,
  __exportMdns,
  __resetNetwork,
  __getMDNExport,
  __getMDNStatus,
  __batchResetPort,
  __getMDNExportList,
  __triggerMDNExport,
  __getMDNExportData,
};
