import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import LockIcon from "@mui/icons-material/Lock";
import InputLabel from "@mui/material/InputLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import { Autocomplete, Button, Chip, TextField } from "@mui/material";

import useAuth from "../../../hooks/useAuth";

const GroupForm = ({
  initialValues,
  mode,
  groupId,
  handleSubmit,
  loading,
  onlyAdmin,
}) => {
  const initialData = {
    group_name: "",
    api_key: "",
    track_port_status: null,
    forward_url: "",
    max_number_change_allowed: null,
    admin_username: "",
    admin_password: "",
    britex_customer_id: [],
    address_list: "",
  };
  const { user } = useAuth();
  const isUpdate = mode === "update" && groupId;
  const nav = useNavigate();
  const [formData, setFormData] = useState(null);

  const createValidationSchema = yup.object({
    group_name: yup
      .string("group_name")
      .required("Group is required")
      .max(80, "Cannot be more than 80 characters long."),
    api_key: yup
      .string("api_key")
      .nullable()
      .max(50, "Cannot be more than 50 characters long."),
    track_port_status: yup.number("track_port_status").nullable(),
    forward_url: yup
      .string("forward_url")
      .nullable()
      .max(140, "Cannot be more than 140 characters long."),
    max_number_change_allowed: yup
      .number("max_number_changed_allowed")
      .nullable(),
    admin_username: yup
      .string("admin_username")
      .required("Admin username is required")
      .test("test space", "Username cannot have spaces", (value) =>
        value ? !(value.indexOf(" ") + 1) : true
      ),
    admin_password: yup
      .string("admin_password")
      .required("Admin password is required")
      .min(4),
    britex_customer_id: yup.array().of(yup.number()),
    address_list: yup.string(),
  });

  const updateValidationSchema = yup.object({
    group_name: yup
      .string("group_name")
      .required("Group is required")
      .max(80, "Cannot be more than 80 characters long."),
    api_key: yup
      .string("api_key")
      .nullable()
      .max(50, "Cannot be more than 50 characters long."),
    track_port_status: yup.number("track_port_status").nullable(),
    forward_url: yup
      .string("forward_url")
      .nullable()
      .max(140, "Cannot be more than 140 characters long."),
    max_number_change_allowed: yup
      .number("max_number_change_allowed")
      .nullable(),
    britex_customer_id: yup.array().of(yup.number()),
    address_list: yup.string().nullable(),
  });

  const adminUpdateValidationSchema = yup.object({
    api_key: yup
      .string("api_key")
      .nullable()
      .max(50, "Cannot be more than 50 characters long."),
    forward_url: yup
      .string("forward_url")
      .nullable()
      .max(140, "Cannot be more than 140 characters long."),
  });

  const validationSchema = isUpdate
    ? onlyAdmin
      ? adminUpdateValidationSchema
      : updateValidationSchema
    : createValidationSchema;

  const formik = useFormik({
    initialValues:
      initialValues && Object.keys(initialValues).length
        ? { ...initialValues }
        : { ...initialData },
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      const requestData = Object.keys(values).reduce((obj, key) => {
        if (
          Object.keys(validationSchema.fields).includes(key) &&
          values[key] !== null
        ) {
          obj[key] = values[key];
        }
        return obj;
      }, {});

      setFormData(requestData);
    },
  });

  useEffect(() => {
    if (formData) {
      handleSubmit(formData);
      if (!loading) {
        formik.setSubmitting(false);
      }
    }
  }, [formData]);

  const generatePassword = () => {
    const length = 20;
    const charset =
      "0123456789abcdefghijklmnopqrstuvwxyz!ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    formik.setFieldValue("api_key", retVal);
  };

  return (
    <>
      <Grid
        sx={{ flexGrow: 1 }}
        container
        rowSpacing={2}
        direction="column"
        alignItems="center"
      >
        <Grid item xs={4} style={{ width: 250 }}>
          <FormControl
            error={
              formik.touched.group_name && Boolean(formik.errors.group_name)
                ? true
                : false
            }
            sx={{ width: "100%" }}
          >
            <InputLabel htmlFor="group_name">Group Name</InputLabel>
            <OutlinedInput
              name="group_name"
              label="Group Name"
              id="group_name"
              value={formik.values.group_name}
              onChange={formik.handleChange}
              disabled={onlyAdmin}
            />
            {formik.touched.group_name && Boolean(formik.errors.group_name) ? (
              <FormHelperText id="group_name-error-text">
                {formik.errors.group_name}
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>

        <Grid item xs={4} style={{ width: 250 }}>
          <FormControl
            error={
              formik.touched.api_key && Boolean(formik.errors.api_key)
                ? true
                : false
            }
            sx={{ width: "100%" }}
          >
            <InputLabel htmlFor="api_key">Api Key</InputLabel>
            <OutlinedInput
              name="api_key"
              label="Api Key"
              id="api_key"
              value={formik.values.api_key || ""}
              onChange={formik.handleChange}
              disabled={onlyAdmin && !initialValues?.api_key}
            />
            <Button
              variant="contained"
              color="primary"
              disabled={onlyAdmin && !initialValues?.api_key}
              onClick={generatePassword}
            >
              Generate Api Key
              <LockIcon />
            </Button>
            {formik.touched.api_key && Boolean(formik.errors.api_key) ? (
              <FormHelperText id="api_key-error-text">
                {formik.errors.api_key}
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>

        <Grid item xs={4} style={{ width: 250 }}>
          <FormControl
            error={
              formik.touched.track_port_status &&
              Boolean(formik.errors.track_port_status)
                ? true
                : false
            }
            sx={{ width: "100%" }}
          >
            <InputLabel htmlFor="track_port_status">
              Track Port Status
            </InputLabel>
            <OutlinedInput
              name="track_port_status"
              label="Track Port Status"
              id="track_port_status"
              value={formik.values.track_port_status}
              onChange={formik.handleChange}
              type="number"
              disabled={onlyAdmin}
            />
            {formik.touched.track_port_status &&
            Boolean(formik.errors.track_port_status) ? (
              <FormHelperText id="track_port_status-error-text">
                {formik.errors.track_port_status}
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>

        <Grid item xs={4} style={{ width: 250 }}>
          <FormControl
            error={
              formik.touched.forward_url && Boolean(formik.errors.forward_url)
                ? true
                : false
            }
            sx={{ width: "100%" }}
          >
            <InputLabel htmlFor="forward_url">Forward URL</InputLabel>
            <OutlinedInput
              name="forward_url"
              label="Forward URL"
              id="forward_url"
              value={formik.values.forward_url}
              onChange={formik.handleChange}
            />
            {formik.touched.forward_url &&
            Boolean(formik.errors.forward_url) ? (
              <FormHelperText id="forward_url-error-text">
                {formik.errors.forward_url}
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>

        <Grid item xs={4} style={{ width: 250 }}>
          <FormControl
            error={
              formik.touched.max_number_change_allowed &&
              Boolean(formik.errors.max_number_change_allowed)
                ? true
                : false
            }
            sx={{ width: "100%" }}
          >
            <InputLabel htmlFor="max_number_change_allowed">
              Max Number Change Allowed
            </InputLabel>
            <OutlinedInput
              name="max_number_change_allowed"
              label="Max Number Change Allowed"
              id="max_number_change_allowed"
              value={formik.values.max_number_change_allowed}
              onChange={formik.handleChange}
              type="number"
              disabled={onlyAdmin}
            />
            {formik.touched.max_number_change_allowed &&
            Boolean(formik.errors.max_number_change_allowed) ? (
              <FormHelperText id="max_number_change_allowed-error-text">
                {formik.errors.max_number_change_allowed}
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        {user && user.id === 1 && (
          <>
            <Grid item xs={4} style={{ width: 250 }}>
              <FormControl
                error={Boolean(formik.errors.britex_customer_id) ? true : false}
              >
                <Autocomplete
                  multiple
                  fullWidth
                  name="britex_customer_id"
                  id="britex_customer_id"
                  options={[]}
                  value={formik.values.britex_customer_id}
                  freeSolo
                  autoSelect
                  style={{ width: 250 }}
                  onChange={(e, values) =>
                    formik.setFieldValue("britex_customer_id", values)
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="britex_customer_id"
                      id="britex_customer_id"
                      label="Brite Customer ID"
                      placeholder="Ids"
                    />
                  )}
                />
                {Boolean(formik.errors.britex_customer_id) ? (
                  <FormHelperText id="britex_customer_id-error-text">
                    Only numbers are allowed
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4} style={{ width: 250 }}>
              <FormControl
                error={
                  formik.touched.address_list &&
                  Boolean(formik.errors.address_list)
                    ? true
                    : false
                }
                sx={{ width: "100%" }}
              >
                <InputLabel htmlFor="address_list">Address List</InputLabel>
                <OutlinedInput
                  name="address_list"
                  label="Address List"
                  id="address_list"
                  value={formik.values.address_list}
                  onChange={formik.handleChange}
                  disabled={onlyAdmin}
                />
                {formik.touched.address_list &&
                Boolean(formik.errors.address_list) ? (
                  <FormHelperText id="address_list-error-text">
                    {formik.errors.address_list}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>

      {!isUpdate && (
        <Grid
          sx={{ flexGrow: 1, mt: 1 }}
          container
          spacing={2}
          rowSpacing={2}
          direction="column"
          alignItems="center"
        >
          <Grid item xs={4} style={{ width: 250 }}>
            <FormControl
              error={
                formik.touched.admin_username &&
                Boolean(formik.errors.admin_username)
                  ? true
                  : false
              }
              sx={{ width: "100%" }}
            >
              <InputLabel htmlFor="admin_username">Admin Username</InputLabel>
              <OutlinedInput
                name="admin_username"
                label="Admin Username"
                id="admin_username"
                value={formik.values.admin_username}
                onChange={formik.handleChange}
              />
              {formik.touched.admin_username &&
              Boolean(formik.errors.admin_username) ? (
                <FormHelperText id="admin_username-error-text">
                  {formik.errors.admin_username}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>

          <Grid item xs={4} style={{ width: 250 }}>
            <FormControl
              error={
                formik.touched.admin_password &&
                Boolean(formik.errors.admin_password)
                  ? true
                  : false
              }
              sx={{ width: "100%" }}
            >
              <InputLabel htmlFor="admin_password">Admin Password</InputLabel>
              <OutlinedInput
                name="admin_password"
                label="Admin Password"
                id="admin_password"
                value={formik.values.admin_password}
                onChange={formik.handleChange}
              />
              {formik.touched.admin_password &&
              Boolean(formik.errors.admin_password) ? (
                <FormHelperText id="admin_password-error-text">
                  {formik.errors.admin_password}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        </Grid>
      )}
      <Grid
        sx={{ flexGrow: 1, mt: 2 }}
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <LoadingButton
            style={{ marginRight: "5px" }}
            onClick={() =>
              !loading && !formik.isSubmitting && formik.submitForm()
            }
            disabled={!!Object.keys(formik.errors).length}
            size="large"
            loading={loading}
            variant="contained"
          >
            {isUpdate ? "Update" : "Create"}
          </LoadingButton>
        </Grid>
        <Grid item>
          <LoadingButton
            style={{ marginRight: "5px" }}
            size="large"
            onClick={() => nav("/group")}
            loading={loading}
            variant="contained"
          >
            Cancel
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
};

export default GroupForm;
