import debounce from "lodash.debounce";
import fileDownload from "js-file-download";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import { Button, Stack } from "@mui/material";

import MdnList from "./MDNList";
import Title from "../../common/Title";
import CustomSelect from "../../common/CustomSelect";

import useAuth from "../../../hooks/useAuth";
import useAxios from "../../../hooks/useAxios";
import { __getUserList } from "../../../api/user";
import { __exportMdns, __getMdns, __getMDNStatus } from "../../../api/mdn";

const MDNAssign = () => {
  const { user } = useAuth();
  const nav = useNavigate();

  const [userResponse, userCall, userApiLoading] = useAxios(__getUserList);
  const [exportResponse, exportCall, exportLoading] = useAxios({
    ...__exportMdns,
  });
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);

  const [mdns, setMdns] = useState([]);
  const [mdnStatus, setMdnStatus] = useState({});
  const [filteredMdn, setFilteredMdns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [metaData, setMetaData] = useState({
    page: 1,
    page_size: 100,
    total: 1,
  });
  const [sortQuery, setSortQuery] = useState();
  const [mdnResponse, mdnCall, mdnLoading] = useAxios({ ...__getMdns });
  const [statusResponse, statusCall, statusLoading] = useAxios({
    ...__getMDNStatus,
  });
  const debouncedFetchData = debounce((query) => {
    mdnCall(query);
  }, 500);

  useEffect(() => {
    if (!mdnResponse || user.id === 1) {
      const filter =
        user.id === 1
          ? {
              filter_column: "user_id",
              filter_operator: "equals",
              filter_value: currentUser,
            }
          : {};

      debouncedFetchData({
        params: {
          page: currentPage,
          page_size: pageSize,
          sort: sortQuery,
          group_id: user.id !== 1 ? user.group_id : null,
          show_user: true,
          ...filter,
        },
      });
    }
    return () => {
      debouncedFetchData.cancel();
    };
  }, [currentPage, pageSize, sortQuery, currentUser]);

  useEffect(() => {
    if (mdnResponse && mdnResponse.status) {
      setMetaData(mdnResponse.data.meta);
      setMdns(mdnResponse.data.data);
      if (mdnResponse.data.data.length > 1 && user.id !== 1) {
        const gateway_ids = mdnResponse.data.data.reduce((gateways, val) => {
          gateways.add(val.gateway_id);
          return gateways;
        }, new Set());
        statusCall({ params: { ids: Array.from(gateway_ids.values()) } });
      }
    }
  }, [mdnResponse]);

  useEffect(() => {
    if (statusResponse && statusResponse.data) {
      setMdnStatus(statusResponse.data);
      setMdns(mdns);
    }
  }, [statusResponse]);

  useEffect(() => {
    if (exportResponse && exportResponse.status) {
      fileDownload(exportResponse.data, "mdn_export.csv");
    }
  }, [exportResponse]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (newPageSize) => {
    setCurrentPage(1);
    setPageSize(newPageSize);
  };

  useEffect(() => {
    if (user.sub_user) return;
    userCall({ params: { page_size: 1000 } });
  }, []);

  useEffect(() => {
    if (userResponse && userResponse.status) {
      setUsers(userResponse.data.data);
    }
  }, [userResponse]);

  useEffect(() => {
    setFilteredMdns(
      user.id !== 1
        ? mdns.filter((val) => {
            if (currentUser) {
              return val.user_id === currentUser;
            }
            return true;
          })
        : [...mdns]
    );
  }, [mdns, currentUser]);

  if (userApiLoading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  return (
    <>
      <Grid container justifyContent="flex-start" spacing={2}>
        {user.id !== 1 && user.admin && (
          <Grid item>
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  exportCall({
                    responseType: "blob",
                    params: { show_user: true },
                  })
                }
              >
                Export MDNs
              </Button>
            </Stack>
          </Grid>
        )}
        <Grid item>
          <Stack spacing={2} direction="row">
            <Button
              variant="contained"
              color="success"
              onClick={() => nav("form")}
            >
              Assign Users Form
            </Button>
          </Stack>
        </Grid>
        <Grid item>
          <CustomSelect
            minWidth={250}
            isClearable={false}
            isSearchable={false}
            closeMenuOnSelect={true}
            options={[
              { value: null, label: "All" },
              ...users.map((val) => ({
                value: val.id,
                label: val.username,
              })),
            ]}
            defaultValue={{ value: null, label: "All" }}
            handleChange={(val) => setCurrentUser(val.value)}
          />
        </Grid>
      </Grid>

      <MdnList
        client={user.id !== 1}
        mdns={filteredMdn}
        assignPage={true}
        disableRowClick={true}
        loading={mdnLoading}
        meta={metaData}
        status={mdnStatus}
        statusLoading={statusLoading}
        handleSort={setSortQuery}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
      />
    </>
  );
};

export default MDNAssign;
