import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button, Stack } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";

import useAuth from "../../hooks/useAuth";
import { allItemTop } from "../../constants/navbarList";

const TopNavBar = ({ handleDrawerToggle }) => {
  const { user } = useAuth();
  const nav = useNavigate();

  const listItemTop = [
    ...allItemTop.filter(
      (eachItem) =>
        (eachItem.isSuperUserView && user.admin && user.id === 1) ||
        (eachItem.isAdminView && user.admin) ||
        (!eachItem.isSuperUserView && !eachItem.isAdminView)
    ),
    {
      text: "Logout",
      to: "/login",
      icon: <LogoutIcon />,
      hasNoLink: true,
      onclick: () => {
        localStorage.clear();
        nav("/login");
      },
    },
  ];

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, display: { sm: "none" } }}
      >
        <MenuIcon />
      </IconButton>
      <Stack
        direction="row"
        spacing={2}
        sx={{ mr: 2, display: { xs: "none", sm: "flex" } }}
      >
        {listItemTop.map((list) => (
          <Button
            color="inherit"
            key={list.text}
            component={Link}
            to={list.to}
            onClick={list?.onclick}
          >
            {list.text}
          </Button>
        ))}
      </Stack>
    </>
  );
};

export default TopNavBar;
