const __getGroups = {
  method: "GET",
  url: "group/",
};

const __getGroupById = (id) => ({
  method: "GET",
  url: `group/${id}`,
});

const __createGroup = {
  method: "POST",
  url: `group/`,
};

const __updateGroup = (id) => ({
  method: "PUT",
  url: `group/${id}`,
});

const __updateGroupSuperAdmin = (id) => ({
  method: "PUT",
  url: `group/${id}/superadmin`,
});

const __updateUser = {
  method: "PUT",
};

const __createUser = {
  method: "POST",
  url: `user/`,
};

const __listRules = {
  method: "GET",
  url: "group/address_rules",
};

const __addAddressRules = {
  method: "POST",
  url: "group/address_rules",
};

const __removeAddressRules = {
  method: "DELETE",
};

export {
  __listRules,
  __getGroups,
  __updateUser,
  __createUser,
  __createGroup,
  __updateGroup,
  __getGroupById,
  __addAddressRules,
  __removeAddressRules,
  __updateGroupSuperAdmin,
};
