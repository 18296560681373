import React, { useEffect, useRef } from "react";

import MuiTableCell from "@mui/material/TableCell";
import { Checkbox, TableContainer, TablePagination } from "@mui/material";
import { AutoSizer, Column, Table, CellMeasurerCache } from "react-virtualized";

import DynamicColumn from "./DynamicColumn";
import useAuth from "../../../hooks/useAuth";
import CodeChip from "../../common/StyledChip";

const delay = (n) => new Promise((resolve) => setTimeout(resolve, n));

const useCancellablePromises = () => {
  const pendingPromises = useRef([]);

  const appendPendingPromise = (promise) =>
    (pendingPromises.current = [...pendingPromises.current, promise]);

  const removePendingPromise = (promise) =>
    (pendingPromises.current = pendingPromises.current.filter(
      (p) => p !== promise
    ));

  const clearPendingPromises = () =>
    pendingPromises.current.map((p) => p.cancel());

  const api = {
    appendPendingPromise,
    removePendingPromise,
    clearPendingPromises,
  };

  return api;
};
const cancellablePromise = (promise) => {
  let isCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (value) => (isCanceled ? reject({ isCanceled, value }) : resolve(value)),
      (error) => reject({ isCanceled, error })
    );
  });

  return {
    promise: wrappedPromise,
    cancel: () => (isCanceled = true),
  };
};

const useClickPreventionOnDoubleClick = (onClick, onDoubleClick) => {
  const api = useCancellablePromises();

  const handleClick = ({ event, index, rowData }) => {
    api.clearPendingPromises();
    const waitForClick = cancellablePromise(delay(300));
    api.appendPendingPromise(waitForClick);

    return waitForClick.promise
      .then(() => {
        api.removePendingPromise(waitForClick);
        onClick({ event, index, rowData });
      })
      .catch((errorInfo) => {
        api.removePendingPromise(waitForClick);
        if (!errorInfo.isCanceled) {
          throw errorInfo.error;
        }
      });
  };

  const handleDoubleClick = () => {
    api.clearPendingPromises();
    onDoubleClick();
  };

  return [handleClick, handleDoubleClick];
};

const ROW_HEIGHT = 48;

const SMSTable = ({
  sms,
  meta,
  loading,
  onRowClick,
  selectedItems,
  handlePageChange,
  onSelectionChange,
  handleAllSelectClick,
  handlePageSizeChange,
}) => {
  const { user } = useAuth();
  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 75,
      defaultHeight: 75, //currently, this is the height the cell sizes to after calling 'toggleHeight'
    })
  );

  useEffect(() => {
    cache.current.clearAll();
  }, [loading]);

  const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(
    ({ event, index, rowData }) => {
      onRowClick(rowData);
    },
    () => null
  );

  const normalUserExcludeCol = ["gateway_id", "port"];

  const columns = [
    {
      dataKey: "gateway_id",
      label: "Gateway",
      flexGrow: 1,
      cellRenderer: (props) => {
        const { rowData } = props;
        return (
          <TableCell {...props}>
            {!rowData.processed ? (
              rowData.gateway_id
            ) : (
              <strong>{rowData.gateway_id}</strong>
            )}
          </TableCell>
        );
      },
    },
    {
      dataKey: "port",
      label: "Port",
      flexGrow: 1,
      cellRenderer: (props) => {
        const { rowData } = props;
        return (
          <TableCell {...props}>
            {!rowData.processed ? (
              rowData.port
            ) : (
              <strong>{rowData.port}</strong>
            )}
          </TableCell>
        );
      },
    },
    {
      dataKey: "time_stamp",
      label: "Time",
      minWidth: 150,
      flexGrow: 2,
      cellRenderer: (props) => {
        const { rowData } = props;
        if (!rowData.time_stamp) {
          return <TableCell {...props}></TableCell>;
        }

        const formattedTime = new Date(rowData.time_stamp).toLocaleString();

        return (
          <TableCell {...props}>
            {!rowData.processed ? (
              formattedTime
            ) : (
              <strong>{formattedTime}</strong>
            )}
          </TableCell>
        );
      },
    },
    {
      dataKey: "ani",
      label: "Source",
      flexGrow: 2,
      cellRenderer: (props) => {
        const { rowData } = props;
        return (
          <TableCell {...props}>
            {!rowData.processed ? rowData.ani : <strong>{rowData.ani}</strong>}
          </TableCell>
        );
      },
    },
    {
      dataKey: "dnis",
      label: "MDN",
      flexGrow: 2,
      cellRenderer: (props) => {
        const { rowData } = props;
        const value =
          rowData.dnis + (rowData.nickname ? ` (${rowData.nickname})` : "");
        return (
          <TableCell {...props}>
            {!rowData.processed ? value : <strong>{value}</strong>}
          </TableCell>
        );
      },
    },
    {
      dataKey: "group_id",
      label: "Owner",
      flexGrow: 2.25,
      cellRenderer: (props) => {
        const { rowData } = props;
        const value = rowData.group_id
          ? `${rowData.group.group_name}:${rowData.user.username}`
          : "";
        return (
          <TableCell {...props}>
            {!rowData.processed ? value : <strong>{value}</strong>}
          </TableCell>
        );
      },
    },
    {
      dataKey: "txt",
      label: "Message",
      minWidth: 300,
      flexGrow: 6,
      cellRenderer: (props) => {
        const { rowData } = props;

        return (
          <TableCell {...props}>
            {!rowData.processed ? (
              <CodeChip text={rowData.txt} />
            ) : (
              <strong>
                <CodeChip text={rowData.txt} />
              </strong>
            )}
          </TableCell>
        );
      },
    },
    {},
  ];

  const headerRenderer = ({ label }) => <TableCell>{label}</TableCell>;

  const getRowStyles = ({ index }) => {
    return {
      display: "flex",
      alignItems: "center",
      boxSizing: "border-box",
      cursor: sms[index]?.processed === -1 ? "default" : "pointer",
    };
  };

  const setRowClassName = ({ index }) => {
    return `row${index % 2 === 0 ? " bg-grey" : ""}${
      sms[index] && selectedItems.indexOf(sms[index].id) !== -1
        ? " bg-grey--dark"
        : ""
    }`;
  };

  const getRowHeight = (prop) => {
    return cache.current.rowHeight({ index: prop.index }) + 5;
  };

  const TableCell = ({ children, ...props }) => {
    const height =
      props?.rowIndex !== undefined
        ? getRowHeight({
            index: props.rowIndex,
          })
        : ROW_HEIGHT;
    return (
      <DynamicColumn cache={cache.current} {...props}>
        <MuiTableCell
          component="div"
          height={height}
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            cursor: "inherit",
            padding: "0px",
            border: "none",
            height: `${(p) => p.height}px`,
          }}
        >
          {children}
        </MuiTableCell>
      </DynamicColumn>
    );
  };

  return (
    <>
      <TableContainer sx={{ height: "67vh" }}>
        <AutoSizer>
          {({ height, width }) => (
            <>
              <Table
                height={height}
                width={width}
                rowHeight={getRowHeight}
                headerHeight={ROW_HEIGHT}
                deferredMeasurementCache={cache.current}
                rowClassName={setRowClassName}
                rowStyle={getRowStyles}
                rowCount={sms?.length}
                rowGetter={({ index }) => sms?.length && sms[index]}
                onRowClick={handleClick}
                onRowDoubleClick={handleDoubleClick}
              >
                <Column
                  style={{
                    display: "flex",
                    alignItems: "center",
                    boxSizing: "border-box",
                  }}
                  flexShrink={0}
                  flexGrow={1}
                  width={1}
                  key={"id"}
                  dataKey={"id"}
                  headerRenderer={(props) => {
                    return (
                      <TableCell>
                        <Checkbox
                          color="primary"
                          indeterminate={
                            selectedItems.length > 0 &&
                            selectedItems.length < sms.length
                          }
                          checked={
                            sms.length > 0 &&
                            selectedItems.length === sms.length
                          }
                          onChange={handleAllSelectClick}
                        />
                      </TableCell>
                    );
                  }}
                  cellRenderer={(props) => {
                    return (
                      <TableCell {...props}>
                        <Checkbox
                          color="primary"
                          checked={
                            selectedItems.length > 0 &&
                            selectedItems.indexOf(props.rowData.id) !== -1
                          }
                          onChange={(event) =>
                            onSelectionChange(props.rowData.id)
                          }
                          onClick={(e) => e.stopPropagation()}
                        />
                      </TableCell>
                    );
                  }}
                />
                {columns
                  .filter((col) =>
                    user.admin
                      ? true
                      : normalUserExcludeCol.includes(col.dataKey) // if normal user check if col is to be excluded
                      ? false
                      : true
                  )
                  .map(({ dataKey, cellRenderer, flexGrow, ...other }) => {
                    return (
                      <Column
                        style={{
                          display: "flex",
                          alignItems: "center",
                          boxSizing: "border-box",
                        }}
                        flexShrink={0}
                        flexGrow={flexGrow}
                        width={flexGrow}
                        key={dataKey}
                        headerRenderer={headerRenderer}
                        cellRenderer={cellRenderer}
                        dataKey={dataKey}
                        {...other}
                      />
                    );
                  })}
              </Table>
            </>
          )}
        </AutoSizer>
      </TableContainer>
      {!loading && (
        <TablePagination
          component="div"
          count={
            meta.last_page
              ? meta.page_size * (meta.page - 1) + sms.length
              : meta.page_size * meta.page + 1
          }
          labelDisplayedRows={({ from, to }) => `${from} - ${to}`}
          page={meta.page - 1}
          onPageChange={(event, newPage) => {
            handlePageChange(newPage + 1);
          }}
          rowsPerPage={meta.page_size}
          onRowsPerPageChange={(event) => {
            handlePageSizeChange(parseInt(event.target.value));
          }}
        />
      )}
    </>
  );
};

export default SMSTable;
