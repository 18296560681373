import { ATT, JASPER } from "./value";

import PersonIcon from "@mui/icons-material/Person";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DataArrayIcon from "@mui/icons-material/DataArray";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

export const allItemTop = [
  {
    text: "SMS",
    to: "/sms",
    icon: <CorporateFareIcon />,
    isSuperUserView: false,
    isAdminView: false,
  },
  {
    text: "Gateways",
    to: "/gateway",
    icon: <ManageAccountsIcon />,
    isSuperUserView: true,
    isAdminView: true,
  },
  {
    text: "PDUs",
    to: "/pdu",
    icon: <ManageAccountsIcon />,
    isSuperUserView: true,
    isAdminView: false,
  },
  {
    text: "Groups",
    to: "/group",
    icon: <PersonIcon />,
    isSuperUserView: true,
    isAdminView: true,
  },
  {
    text: "MDNs",
    to: "/mdn",
    icon: <AddCircleIcon />,
    isSuperUserView: false,
    isAdminView: false,
  },
];
