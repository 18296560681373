import { format } from "date-fns";
import React, { useState } from "react";

import Box from "@mui/material/Box";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { LoadingButton } from "@mui/lab";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";

import Title from "../../common/Title";
import CustomSelect from "../../common/CustomSelect";
import { gatewayOperation } from "../../../constants/value";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function SlotEnableModal({
  operation,
  handleClose,
  gatewayData,
  handleSubmit,
  loading,
  selectedModel,
  secondarySubmit,
}) {
  const minSlot = selectedModel.length
    ? gatewayData.reduce((min, val) => {
        if (!selectedModel.includes(val.id)) return min;
        return Math.min(min, val.ports);
      }, Number.MAX_SAFE_INTEGER)
    : 0;
  const [slot, setSlot] = useState();
  const [waitTime, setWaitTime] = useState(300);
  const [rebootInterval, setRebootInterval] = useState(0);
  const [schedule, setSchedule] = useState(false);
  const [scheduleTime, setScheduleTime] = useState(
    new Date(new Date().setHours(0, 0, 0, 0))
  );

  return (
    <Modal open={operation} onClose={() => handleClose(false)}>
      <Box sx={style}>
        <Title
          variant="h5"
          content={`${operation} Gateway Slot`}
          gutterBottom
        />
        <Grid container spacing={5}>
          {operation !== gatewayOperation.SetReboot &&
            (operation !== gatewayOperation.Rotate ? (
              <Grid item>
                <CustomSelect
                  helperText={"Slot"}
                  placeholder={"Slot"}
                  isClearable={false}
                  isSearchable={false}
                  closeMenuOnSelect={true}
                  options={[
                    ...Array.from({ length: minSlot + 1 }, (x, i) => ({
                      value: i,
                      label: i === 0 ? "All" : i,
                    })),
                  ]}
                  defaultValue={{
                    value: slot,
                    label: slot === 0 ? "All" : slot,
                  }}
                  handleChange={(val) => setSlot(val.value)}
                />
              </Grid>
            ) : (
              <>
                <Grid item>
                  <InputLabel htmlFor="wait_time">
                    Rotate Wait Time (in seconds)
                  </InputLabel>
                  <OutlinedInput
                    name="wait_time"
                    label="Rotate Wait Time"
                    id="wait_time"
                    value={waitTime}
                    type="number"
                    onChange={(e) => setWaitTime(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    label="Schedule Slot Rotation"
                    control={
                      <Checkbox
                        value={schedule}
                        onChange={(e) => setSchedule(e.target.checked)}
                      />
                    }
                  />
                </Grid>
                {schedule && (
                  <Grid item xs={12} minWidth={"100%"}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="Daily Slot Rotation Time"
                        value={new Date(scheduleTime)}
                        onChange={(newValue) => setScheduleTime(newValue)}
                      />
                    </LocalizationProvider>
                  </Grid>
                )}
              </>
            ))}
          {operation === gatewayOperation.SetReboot && (
            <Grid item>
              <InputLabel htmlFor="reboot_interval">
                Reboot Interval (in days)
              </InputLabel>
              <OutlinedInput
                name="reboot_interval"
                label="Reboot Interval (in days)"
                id="reboot_interval"
                value={rebootInterval}
                type="number"
                onChange={(e) => setRebootInterval(e.target.value)}
              />
            </Grid>
          )}
        </Grid>
        <Grid container mt={2}>
          <Grid container spacing={5} justifyContent="left"></Grid>
          <Grid item>
            <LoadingButton
              style={{ marginLeft: "8px" }}
              variant="contained"
              color="primary"
              loading={loading}
              disabled={
                loading || (isNaN(slot) && !waitTime && isNaN(rebootInterval))
              }
              onClick={() => {
                // if rebootInterval, set reboot interval
                const data =
                  operation === gatewayOperation.SetReboot
                    ? {
                        ids: selectedModel,
                        reboot_interval: rebootInterval,
                      }
                    : operation !== gatewayOperation.Rotate
                    ? {
                        ids: selectedModel,
                        slot: slot,
                      }
                    : {
                        ids: selectedModel,
                        schedule: schedule,
                        slot_rotation_wait_time: waitTime,
                        slot_rotation_schedule:
                          format(scheduleTime, "yyyy-MM-dd") +
                          "T" +
                          format(scheduleTime, "HH:mm"),
                      };
                handleSubmit({
                  data: data,
                });
              }}
            >
              {schedule
                ? `Schedule ${operation} Slot`
                : operation === gatewayOperation.SetReboot
                ? "Set Reboot Interval"
                : `${operation} Slot`}
            </LoadingButton>
          </Grid>
          {operation === gatewayOperation.Rotate && (
            <Grid item>
              <LoadingButton
                style={{ marginLeft: "8px" }}
                variant="contained"
                color="secondary"
                loading={loading}
                disabled={
                  loading || (isNaN(slot) && !waitTime && isNaN(rebootInterval))
                }
                onClick={() => secondarySubmit({ data: selectedModel })}
              >
                {"Remove scheduled slot rotation"}
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </Box>
    </Modal>
  );
}
