import React, { useState, useEffect } from "react";

import Paper from "@mui/material/Paper";
import { InputAdornment } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";

export default function Search({
  searchValue,
  changeSearchValue,
  clearSearch,
}) {
  const handleChange = (e) => {
    changeSearchValue(e.target.value);
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <Paper
      component="form"
      sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 300 }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        onChange={handleChange}
        onSubmit={(e) => e.preventDefault()}
        onKeyDown={handleEnter}
        placeholder="Search"
        inputProps={{ "aria-label": "search" }}
        value={searchValue}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
      <IconButton
        disabled={!searchValue}
        onClick={clearSearch}
        sx={{ p: "10px" }}
        aria-label="search"
      >
        <CancelIcon />
      </IconButton>
    </Paper>
  );
}
