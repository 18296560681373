import { useReducer } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import PrivateRoute from "../routes/PrivateRoutes";
import { initialAuth, authReducer } from "../reducer/auth";
import { initialAlert, alertReducer } from "../reducer/alert";
import { initialLoading, loadingReducer } from "../reducer/loading";
import { AuthContext, AlertContext, LoadingContext } from "../context/index";

import Login from "./auth/Login";
import Home from "./views/home/Home";
import AlertBox from "./common/AlertBox";
import LoadingBox from "./common/LoadingBox";

import Mdn from "./views/MDN/MDN";
import Sms from "./views/SMS/SMS";
import SMSOld from "./views/SMS/SMSOld";

import Group from "./views/Group/Group";
import UpdateUser from "./views/Group/UserUpdate";
import CreateUser from "./views/Group/UserCreate";
import GroupCreate from "./views/Group/GroupCreate";
import GroupUpdate from "./views/Group/GroupUpdate";

import Gateway from "./views/Gateway/Gateway";
import GatewayUpdate from "./views/Gateway/GatewayUpdate";
import GatewayCreate from "./views/Gateway/GatewayCreate";
import GatewayActions from "./views/Gateway/GatewayActions";
import ScanHistory from "./views/Gateway/ScanHistory/ScanHistory";
import GatewayHistoryDetails from "./views/Gateway/GatewayHistoryDetails";
import RotateSlotStatus from "./views/Gateway/RotateSlotHistory/RotateSlotStatus";
import RotateSlotHistory from "./views/Gateway/RotateSlotHistory/RotateSlotHistory";

import MDNClear from "./views/MDN/MDNClear";
import MDNUpdate from "./views/MDN/MDNUpdate";
import MDNCreate from "./views/MDN/MDNCreate";
import MDNAssign from "./views/MDN/MDNAssign";
import MDNExport from "./views/MDN/MDNExport";
import MDNAlias from "./views/MDN/MDNAliasForm";
import MDNResetForm from "./views/MDN/MDNResetForm";
import MDNExportView from "./views/MDN/MDNExportView";
import MDNAssignForm from "./views/MDN/MDNAssignForm";

import AdminRoutes from "../routes/AdminRoutes";
import SuperAdminRoutes from "../routes/SuperAdminRoutes";
import SMSReceiveTest from "./views/Gateway/SMSTest/SMSReceiveTest";
import SMSReceiveDetails from "./views/Gateway/SMSTest/SMSReceiveDetails";

import PDU from "./views/Gateway/PDU/PDU";
import PDUCreate from "./views/Gateway/PDU/PDUCreate";
import PDUUpdate from "./views/Gateway/PDU/PDUUpdate";
import AddressRule from "./views/Gateway/AddressRule/AddressRule";

function App() {
  const [auth, authDispatch] = useReducer(authReducer, initialAuth);
  const [alert, alertDispatch] = useReducer(alertReducer, initialAlert);
  const [isLoading, loadingDispatch] = useReducer(
    loadingReducer,
    initialLoading
  );
  return (
    <BrowserRouter>
      <AuthContext.Provider value={[auth, authDispatch]}>
        <AlertContext.Provider value={[alert, alertDispatch]}>
          <LoadingContext.Provider value={[isLoading, loadingDispatch]}>
            <AlertBox />
            <LoadingBox />
            <Routes>
              <Route path="/login" exact element={<Login />} />
              <Route path="/" element={<PrivateRoute />}>
                <Route index element={<Home />} />
                <Route path="/sms">
                  <Route index element={<SMSOld />} />
                </Route>
                <Route path="/sms-old">
                  <Route index element={<Sms />} />
                </Route>

                <Route path="/group" element={<AdminRoutes />}>
                  <Route index element={<Group />} />
                  <Route path="create" element={<SuperAdminRoutes />}>
                    <Route index element={<GroupCreate />} />
                  </Route>
                  <Route path=":group_id" element={<GroupUpdate />} />
                  <Route path="update-user" element={<UpdateUser />} />
                  <Route path="create-user" element={<CreateUser />} />
                </Route>

                <Route path="/gateway" element={<AdminRoutes />}>
                  <Route index element={<Gateway />} />
                  <Route path="sms_test" element={<SuperAdminRoutes />}>
                    <Route index element={<SMSReceiveTest />} />
                    <Route path=":batch_id" element={<SMSReceiveDetails />} />
                  </Route>
                  <Route path=":gateway_id" element={<SuperAdminRoutes />}>
                    <Route index element={<GatewayUpdate />} />
                  </Route>
                  <Route
                    path=":gateway_id/details"
                    element={<GatewayHistoryDetails />}
                  />
                  <Route path="create" element={<SuperAdminRoutes />}>
                    <Route index element={<GatewayCreate />} />
                  </Route>
                  <Route
                    path="gateway-actions/:batch_id"
                    element={<GatewayActions />}
                  />
                  <Route path="address_rules" element={<AdminRoutes />}>
                    <Route index element={<AddressRule />} />
                  </Route>
                </Route>

                <Route path="/mdn">
                  <Route path="mdn_export" element={<SuperAdminRoutes />}>
                    <Route index element={<MDNExport />} />
                    <Route path=":id" element={<MDNExportView />} />
                  </Route>
                  <Route path="batch_reset" element={<MDNResetForm />} />
                  <Route index element={<Mdn />} />
                  <Route path="add" element={<MDNCreate />} />
                  <Route path="assign">
                    <Route index element={<MDNAssign />} />
                    <Route path="form" element={<MDNAssignForm />} />
                  </Route>
                  <Route path="alias" element={<MDNAlias />} />

                  <Route path="clear" element={<MDNClear />} />
                  <Route path=":mdn_id" element={<MDNUpdate />} />

                  <Route path="update-user" element={<UpdateUser />} />
                  <Route path="create-user" element={<CreateUser />} />
                </Route>

                <Route path="/scan_history" element={<AdminRoutes />}>
                  <Route index element={<ScanHistory />} />
                </Route>
                <Route path="/rotate_history" element={<AdminRoutes />}>
                  <Route index element={<RotateSlotHistory />} />
                  <Route
                    path=":rotate_slot_id/status"
                    element={<RotateSlotStatus />}
                  />
                </Route>

                <Route path="/pdu" element={<SuperAdminRoutes />}>
                  <Route index element={<PDU />} />
                  <Route path="create" element={<PDUCreate />} />
                  <Route path=":pdu_id" element={<PDUUpdate />} />
                </Route>

                <Route path="*" element={<h1>404 Error!!!</h1>} />
              </Route>
            </Routes>
          </LoadingContext.Provider>
        </AlertContext.Provider>
      </AuthContext.Provider>
    </BrowserRouter>
  );
}

export default App;
